import Vue from 'vue'

const PAGE_NAME_MAP = { page_goods_group: 'shein_picks', page_daily_new: 'daily_new', } // 页面标识映射

// 混入pretreatInfo，实现数据动态响应
const setPretreatInfo = ({ goodsItem = null, field = null, value = null } = {}) => {
  if (!goodsItem || !field) return
  if (!goodsItem.pretreatInfo) Vue.set(goodsItem, 'pretreatInfo', {})
  Vue.set(goodsItem.pretreatInfo, field, value)
}

// 入参错误校验
const checkParams = ({ goods, item, fields, pageKey, sceneKey }) => {
  let errLog = ''
  let { enableDecisionAttr, cccTspBadges, cccFeedback, skuPrices, promoLabel, userBehaviorLabel, beltLabel, rankingList } = fields
  if (item) {
    if (!item.goods_id) {
      errLog += '商品缺少goods_id；'
    }
    // if (skuPrices && !item.sku_code) {
    //   errLog += `goods_id: ${item.goods_id}，缺少sku_code；`
    // }
    if ((enableDecisionAttr || cccTspBadges) && !item.cat_id) {
      errLog += `goods_id: ${item.goods_id}，缺少cat_id；`
    }
  } else {
    if (!goods?.length) {
      errLog += 'goods为空；'
    }
  
    if ((enableDecisionAttr || cccTspBadges || cccFeedback) && (!pageKey || !sceneKey)) {
      if (!pageKey) {
        errLog += '; 缺少pageKey；'
      }
      if (!sceneKey) {
        errLog += '; 缺少sceneKey；'
      }
    }
    if ((promoLabel || userBehaviorLabel || beltLabel || rankingList) && !pageKey) {
      errLog += '; 缺少pageKey'
    }
  }

  if (errLog) console.error(errLog)

  return errLog
}

// 处理入参
function handleParams ({ goods, fields, pageKey, subPageKey, sceneKey, caller, cccParams, isPaid, mallInfo, abtInfo }) {
  const { locateLabels, enableDecisionAttr, cccTspBadges, cccFeedback, skuPrices, catName, quickShip, starComment, promoLabel, userBehaviorLabel, beltLabel, rankingList, labelsBeforeGoodsName } = fields
  if (checkParams({ goods, fields, pageKey, sceneKey })) return { paramsErr: true }
  
  let paramsErr = false
  const atomicParams = []
  const goodsIds = []
  const goodsIdMallCodes = []
  const goodsIdObj = {}
  for(let i = 0; i < goods.length; i++) {
    const { goods_id, goods_sn, cat_id, sku_code, mall_code, promotionId, productRelationID, goods_relation_id } = goods[i]
    const spuId = productRelationID || goods_relation_id // productRelationID 主商品的spu字段，goods_relation_id 色块的spu字段
    // 去重
    if (!goodsIdObj[goods_id]) {

      goodsIdObj[goods_id] = 1

      if (checkParams({ item: goods[i], fields })) {
        paramsErr = true
        break
      }
      const reqParams = {}
      if (goods_id && mall_code) goodsIdMallCodes.push({ goods_id, mall_code })
      if (mall_code) Object.assign(reqParams, { mall_code })
      if (goods_id) {
        goodsIds.push({ goods_id })
        Object.assign(reqParams, { goods_id })
      }
      if (goods_sn) Object.assign(reqParams, { goods_sn })
      if (promotionId) Object.assign(reqParams, { promotionId })
      if (cat_id && (enableDecisionAttr || cccTspBadges || catName || rankingList)) Object.assign(reqParams, { cat_id })
      if (sku_code && (skuPrices || quickShip)) Object.assign(reqParams, { sku_code })
      if (spuId && starComment) Object.assign(reqParams, { spuId })
      
      atomicParams.push(reqParams)
    }

  }

  const reqParams = { atomicParams, fields, caller, isPaid, mallInfo, abtInfo }
  if (locateLabels || enableDecisionAttr || cccTspBadges || cccFeedback || promoLabel || userBehaviorLabel || beltLabel || rankingList || labelsBeforeGoodsName) {
    reqParams.scene = {
      sceneKey: enableDecisionAttr || cccTspBadges || cccFeedback ? sceneKey : undefined,
      pageKey: PAGE_NAME_MAP[pageKey] || pageKey,
      subPageKey
    }
  }
  if (Object.keys(cccParams || {}).length) {
    Object.assign(reqParams, { cccParams })
  }

  return { reqParams, goodsIds, goodsIdMallCodes, paramsErr }
}

// 混入原子接口内容
const  assignAtomicInfo = ({ goods, atomicInfo, wishStatus, realTimePrices, cb, itemConfig = {} }) => {
  goods.forEach(item => {
    const { goods_id } = item
    const goodsIdMapAtomicInfo = { ...(atomicInfo?.[goods_id] || {}), ...(wishStatus?.[goods_id] || {}), ...(realTimePrices?.[goods_id] || {}) }
    // 特殊处理，记录过期价格
    if (itemConfig.showPriceCut) {
      Object.assign(item, { expired_salePrice: item.salePrice })
    }
    Object.keys(goodsIdMapAtomicInfo).forEach(key => {
      const value = goodsIdMapAtomicInfo[key]
      // goods_id不需要混入，多数情况goods_id作为循环的key，但不同接口返回的goods_id数据类型不同，会导致列表闪烁
      key !== 'goods_id' && Vue.set(item, key, value)
    })
    
    // 混入结束后的回调
    cb && typeof cb === 'function' && cb(item)
  })

  return goods
}

// 切割数组队列
function sliceArray (arr, limit, result = []) {
  const copyArr = [...arr]
  const data = copyArr.splice(0, limit)
  result.push(data)
  if (copyArr.length) {
    return sliceArray(copyArr, limit, result)
  }
  return result
}

const includsPageKey = ({ pageKey }) => {
  return ['page_real_class', 'page_select_class', 'daily_new', 'shein_picks', 'page_coupon_product', 'page_picked', 'page_real_time_feedback', 'page_search'].includes(PAGE_NAME_MAP[pageKey] || pageKey)
}

export {
  setPretreatInfo,
  checkParams,
  handleParams,
  assignAtomicInfo,
  sliceArray,
  includsPageKey
}
