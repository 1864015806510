/**
 * 获取公共模块多语言
 */
import schttp from 'public/src/services/schttp'
class CommonI18n {
  constructor() {
    if(typeof window !== 'undefined') {
      this.init()
    } 
  }
  i18n = {}
  pools = []
  pages = ['quick_add']
  async init() {
    try {
      this.i18n = await schttp({
        url: '/api/common/i18nBatch/get',
        params: {
          pages: this.pages
        },
        timeout: 10000
      })

      while(this.pools.length) {
        let fn = this.pools.shift()
        fn()
      }
    } catch (error) {
      console.log('getI18nBatchError', error)
    }
  }
  loadByQueue(page) {
    return new Promise((resolve) => {
      if(Object.keys(this.i18n).length) {
        resolve(this.i18n[page])
      } else {
        this.pools.push(() => resolve(this.i18n[page]))
      }
    })
  }
}


export default new CommonI18n()
