import schttp from 'public/src/services/schttp'
import { abtservice } from 'public/src/services/abt'
// ! 公共 START
/**
 * 请求ccc poskey返回的param中的key 【poskey，param】
 *
 * @type {Map<string, string>}
 */
export const CCC_RECOMMEND_ABT_RETURN_KEY = new Map([
  ['PreSearchRecommend', 'PreSearchRecommend'],
  ['FindSimilarRecommend', 'FindSimilarRecommend'],
  ['pageFindSimilar', 'pageFindSimilarRecommend'],
  ['FlashSaleRecommend', 'FlashSaleRecommend'],
  ['realListPage', 'RealClassFilteredRecommend'],
  ['realListEmptyPage', 'RealClassEmptyRecommend'],
  ['selectListPage', 'SelectClassFilteredRecommend'],
  ['selectListEmptyPage', 'SelectClassEmptyRecommend'],
  ['otherListPage', 'OtherListFilteredRecommend'],
  ['otherListEmptyPage', 'OtherListEmptyRecommend'],
  ['searchListPage', 'SearchRecommend'],
  ['searchListEmptyPage', 'SearchEmptyRecommend'],
  ['favoriteListPage', 'CollectionRecommend'],
  ['favoriteListEmptyPage', 'CollectionEmptyRecommend'],
  ['LogisticTrackRecommend', 'LogisticTrackRecommend'],
  ['pointsPage', 'PointsRecommend'],
  ['signInPage', 'SignInRecommend'],
  ['shoppingCartPage', 'shoppingCartRecommend'], // 购物车
  ['shoppingCartCouponPage', 'shoppingCartCouponRecommend'], // 购物车券筛选
  ['pageCart', 'pageCartRecommend'], // 购物车低客单拦截推荐
  ['pageBeshared', 'pageBesharedRecommend'], // 购物车分享落地页
  ['deliveryConfirmationPage', 'ConfirmDeliveryRecommend'],
  ['cancelPartialOrderPage', 'CancelPartialOrderRecommend'],
  ['unpaidorderCancelPage', 'UnpaidorderCancelRecommend'],
  ['repeatPurchasePage', 'RepurchaseRecommend'],
  ['paymentSuccessfulPage', 'PaymentSuccessfulRecommend'],
  ['orderDetailPage', 'OrderDetailRecommend'],
  ['messagePage', 'MessageRecommend'],
  ['personalCenterPage', 'PersonalCenterRecommend'],
  ['gamePage', 'GameRecommend'],
  ['orderListPage', 'orderListRecommend'], //订单列表
  ['couponPage', 'couponRecommend'], //优惠券
  ['orderCommentPage', 'orderCommentRecommend'], //订单评论
  ['returnSuccessfulPage', 'returnSuccessfulRecommend'], //退货成功
  ['refundDetailPage', 'refundDetailRecommend'], //退款详情
  ['collectionBoardsPage', 'collectionBoardsRecommend'],
  ['collectionBoardsEmptyPage', 'collectionBoardsEmptyRecommend'],
  ['saverPage', 'saverRecommend'], //超省卡
])
/**
 * @property {object} AutoFirstLimit
 * @param {element} AutoFirstLimit.el 传入当前容器元素
 * @param {element} AutoFirstLimit.useBoundingClientRect 是否使用getBoundingClientRect获取当前容器高度，默认使用offsettop（避免offsettop导致高度获取错误）
 */
/**
 * 获取ccc推荐配置 poskey/pageType均由产品提供
 * 支持传递对象形式
 *
 * @async
 * @param {string | object} [originPageType='FindSimilarRecommend'] poskey或传入对象
 * @param {string} [originPoskey='findSimilarRecommend']  页面类型
 * @param {object} [originCccComposeDataParams]  cccx推荐数据定制化
 * @param {string} [originPlaceHolderKeys]  板块实验 placeHolderKey 配置。多个配置通过逗号拼接
 * @param {OriginAutoFirstLimit} [originAutoFirstLimit]  计算首屏推荐数量
 * @param {boolean} [isSkipLanguage=false] 是否需要请求多语言
 * @returns {[object,object]} [ccc配置,语言配置]
 */
const getCccRecommendConfig = async (
  originPageType = 'findSimilarRecommend',
  originPoskey = 'findSimilarRecommend',
  originCccComposeDataParams,
  originPlaceHolderKeys,
  originAutoFirstLimit,
) => {
  try {
    // 推荐初始化打点 - 开始时间
    const startTime = Date.now()
    let poskey, pageType, cccComposeDataParams, placeHolderKeys, autoFirstLimit, isSkipLanguage
    if (typeof originPageType === 'string') {
      poskey = originPoskey
      pageType = originPageType
      cccComposeDataParams = originCccComposeDataParams
      placeHolderKeys = originPlaceHolderKeys
      autoFirstLimit = originAutoFirstLimit
    } else if (typeof originPageType === 'object') {
      poskey = originPageType.poskey
      pageType = originPageType.pageType
      cccComposeDataParams = originPageType.cccComposeDataParams
      placeHolderKeys = originPageType.placeHolderKeys
      autoFirstLimit = originPageType.autoFirstLimit
      isSkipLanguage = originPageType.isSkipLanguage
    }
    // 根据视口高度与推荐容器位置计算剩余尺寸
    if (typeof window !== 'undefined' && autoFirstLimit?.el) {
      const windowHeight = window.innerHeight
      const windowWidth = document.body.clientWidth
      const positionTop = autoFirstLimit.useBoundingClientRect ? autoFirstLimit.el?.getBoundingClientRect().top : autoFirstLimit.el?.offsetTop
      const restHeight = windowHeight - positionTop
      if (restHeight > 0 ) {
        const containerSize = {
          height: restHeight,
          width: windowWidth
        }
        if (cccComposeDataParams) {
          cccComposeDataParams.containerSize = containerSize
        } else {
          cccComposeDataParams = { containerSize }
        }
      }
    }
    // eslint-disable-next-line @shein-aidc/abt/abt
    const abtResult = (await abtservice.getUserAbtResult({ newPosKeys: poskey })) || {}
    const p = CCC_RECOMMEND_ABT_RETURN_KEY.get(poskey) || '' // abt 返回的p/param
    const abtBranch = abtResult?.[poskey]?.param?.[p] || 'default'
    if (abtResult?.[poskey]?.param && !abtResult?.[poskey]?.param?.[p]) {
      abtResult[poskey].param[p] = 'default'
    }
    let sceneConfigParams = {}
    if (placeHolderKeys) {
      sceneConfigParams = {
        pageType,
        blockAbtParams: JSON.stringify(abtResult?.[poskey]?.param),
        cccComposeDataParams,
        placeHolderKeys,
      }
    } else {
      sceneConfigParams = {
        pageType,
        blockAbtParams: abtResult?.[poskey]?.param ? JSON.stringify(abtResult?.[poskey]?.param) : `{"${p}": "default"}`,
        abtBranch,
        cccComposeDataParams
      }
    }
    const requestList = [
      schttp({
        method: 'POST',
        url: '/api/ccc/sceneConfig/get',
        data: sceneConfigParams,
      }),
    ]
    if (!isSkipLanguage) {
      requestList.push(
        schttp({
          method: 'POST',
          url: '/api/ccc/language/query',
          data: {
            types: ['recomendFlow', 'itemLanguage', 'promotionLanguage']
          }
        }))
    }
    const [cccxConfig, cccxLangRes]  = await Promise.all(requestList)
    const cccComponentList = cccxConfig?.data?.content || []
    if (cccComponentList.length) {
      cccComponentList.forEach(compo => {
        if (compo?.componentKey === 'PRODUCT_RECOMMEND_COMPONENT') {
          compo.startTime = startTime
        }
      })
    }
    return [cccxConfig?.data || null, cccxLangRes?.data || null]
  } catch (error) {
    console.log(error)
    return [null, null]
  }
}
/**
 * 
 * @param {Object} params
 * @param {String} params.pageType ccc页面类型
 * @param {String} params.isSkipLanguage 是否需要请求多语言
 * @returns 
 */
export const getBffCccRecommendConfig = async ({
  pageType,
  isSkipLanguage,
}) => {
  try {
    // 推荐初始化打点 - 开始时间
    const startTime = Date.now()
    const requestList = [
      schttp({
        method: 'GET',
        url: '/ccc/common_component',
        useBffApi: true,
        params: {
          cccPageType: pageType
        },
      }),
    ]
    if (!isSkipLanguage) {
      requestList.push(
        schttp({
          method: 'POST',
          url: '/api/ccc/language/query',
          data: {
            types: ['recomendFlow', 'itemLanguage', 'promotionLanguage']
          }
        }))
    }
    const [cccxConfig, cccxLangRes]  = await Promise.all(requestList)
    const cccComponentList = cccxConfig?.info?.content || []
    if (cccComponentList.length) {
      cccComponentList.forEach(compo => {
        if (compo?.componentKey === 'PRODUCT_RECOMMEND_COMPONENT') {
          compo.isAppBff = true
          compo.startTime = startTime
        }
      })
    }
    const cccxConfigData = cccxConfig?.info || null
    return [cccxConfigData, cccxLangRes?.data || null]
  } catch (error) {
    console.error(error)
    return [null, null]
  }
}
// ! 公共 END
// ! 列表页 START
const LIST_PARAMS = ['realListPage', 'realListPage']
const LIST_EMPTY_PARAMS = ['realListEmptyPage', 'realListEmptyPage']
const LIST_OTHER_PARAMS = ['otherListPage', 'otherListPage']
const LIST_OTHER_EMPTY_PARAMS = ['otherListEmptyPage', 'otherListEmptyPage']
/**
 * ccc推荐组件列表页参数，列表页的【ListRecommend.vue】组件被多个页面应用，所以根据页面会向ccc传递不同的参数，
 * 结构如下：[page_name,[pageType, poskey]]] 结合产品文档以sa的page name为key
 * page_name: 页面名称；
 * pageType: ccc页面类型；
 * poskey: ccc poskey；
 *
 * @type {Map<string, [string, string]>}
 */
const LIST_RECOMMEND_CCC_CONFIG = new Map([
  ['page_search', ['searchListPage', 'searchListPage']],
  ['page_select_class', ['selectListPage', 'selectListPage']],
  ['page_real_class', LIST_PARAMS],
  ['page_virtual_class', LIST_OTHER_PARAMS],
  ['page_other', LIST_OTHER_PARAMS],
  ['page_goods_group', LIST_OTHER_PARAMS],
  ['page_coupon_product', LIST_OTHER_PARAMS],
])
/**
 * ccc推荐组件列表页【空态】参数，
 * 结构如下：[page_name,[pageType, poskey]]]
 * page_name: 页面名称；
 * pageType: ccc页面类型；
 * poskey: ccc poskey；
 *
 * @type {Map<string, [string, string]>}
 */
const LIST_RECOMMEND_CCC_EMPTY_CONFIG = new Map([
  ['page_search', ['searchListEmptyPage', 'searchListEmptyPage']],
  ['page_select_class', ['selectListEmptyPage', 'selectListEmptyPage']],
  ['page_real_class', LIST_EMPTY_PARAMS],
  ['page_virtual_class', LIST_OTHER_EMPTY_PARAMS],
  ['page_other', LIST_OTHER_EMPTY_PARAMS],
  ['page_goods_group', LIST_OTHER_EMPTY_PARAMS],
  ['page_coupon_product', LIST_OTHER_EMPTY_PARAMS],
])

/**
 * 获取Ccc请求参数 区分空态和非空态
 *
 * @param {string} [pageName='']
 * @param {boolean} [isEmpty=false]
 * @returns {[string, string]}
 */
export const getListCccRecommendConfigParams = (pageName = '', isEmpty = false) => {
  return (isEmpty ? LIST_RECOMMEND_CCC_EMPTY_CONFIG.get(pageName) : LIST_RECOMMEND_CCC_CONFIG.get(pageName)) || LIST_OTHER_EMPTY_PARAMS
}

// todo 部分页面不接入新的CCC推荐组件 $route.meta.type判断 没找到更好的方式
export const OLD_RECOMMEND_PAGES = ['daily_new']
/**
 * CCC组件abt实验切换[pn/key]映射
 *
 * @type {Map<string, [string, string]>}
 */
const CCC_AB_TEST_MAP = new Map([
  ['page_search', 'searchListPage'],
  ['page_select_class', 'selectListPage'],
  ['page_real_class', 'realListPage'],
  ['page_other', 'otherListPage'],
  ['page_goods_group', 'otherListPage'],
  ['page_virtual_class', 'otherListPage'],
  ['page_coupon_product', 'otherListPage'],
])

/**
 * CCC组件abt实验切换[pn/key]映射【空态版本】
 *
 * @type {Map<string, [string, string]>}
 */
const CCC_EMPTY_AB_TEST_MAP = new Map([
  ['page_search', 'searchListEmptyPage'],
  ['page_select_class', 'selectListEmptyPage'],
  ['page_real_class', 'realListEmptyPage'],
  ['page_other', 'otherListEmptyPage'],
  ['page_goods_group', 'otherListEmptyPage'],
  ['page_virtual_class', 'otherListEmptyPage'],
  ['page_coupon_product', 'otherListEmptyPage'],
])

/**
 * 获取Ccc组件开关的abt poskey 区分空态和非空态
 *
 * @param {string} [pageName='']
 * @param {boolean} [isEmpty=false]
 * @returns {[string, string]}
 */
export const getCccRecommendAbtPoskey = (pageName = '', isEmpty = false) => {
  return (isEmpty ? CCC_EMPTY_AB_TEST_MAP.get(pageName) : CCC_AB_TEST_MAP.get(pageName)) || 'otherListEmptyPage'
}
// ! 列表页 END

export default getCccRecommendConfig
