export const BFF_APOLLO_CONFIG = [
  'setting_v2',
  'setting_v3',
  'language_v1',
  'notification_v1',
  'notification_v2',
  'email_validate_v1',
  'phone_manage_v1',
  'userCenter_v1',
]
