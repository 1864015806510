import schttp from 'public/src/services/schttp'
import i18n from 'public/src/pages/common/biz_helper/i18n' 
import { assignAtomicInfo, handleParams } from './tools'
class ItemInterfaceSer {
  constructor () {
    if (typeof window === 'undefined') return

    this.init()
  }

  async init () {
    this.language = await i18n.loadByQueue('item')
  }

  /************* 静态展示接口 *************/
  /**
   * 商品项服务接口
   * @param {Array} goods 商品列表
   * @param {Object} fields 请求颗粒
      * @param {Boolean} mallInfo mall相关信息
      * @param {Boolean} productInfo 详细信息，基本涵盖了商品所有的基础信息 - 单个
      * @param {Boolean} baseInfo 基础信息（goods_name，goods_id, goods_img等）- 批量
      * @param {Boolean} prices 价格（缓存20分钟）
      * @param {Boolean} skuPrices sku价格
      * @param {Boolean} realTimePricesWithPromotion 价格、促销（实时）
      * @param {Boolean} vipDiscountPrices vip专属价格
      * @param {Boolean} sheinClubPromotionInfo 付费会员促销信息
      * @param {Boolean} videoIcon 视频图标（仅romwe）
      * @param {Boolean} detailImage 详细图
      * @param {Boolean} relatedProducts 色块商品（包含主商品）
      * @param {Boolean} relatedProductsOnly 色块商品（不包含主商品）
      * @param {Boolean} soldOutColors 售罄色块
      * @param {Boolean} promotion 促销
      * @param {Boolean} topPick romwe originals原创角标
      * @param {Boolean} stock 库存（总库存，is_on_sale在售状态）
      * @param {Boolean} cccTspBadges cccTsp标签（new, top rated等）
      * @param {Boolean} seriesAndBrand 品牌、系列
      * @param {Boolean} wish 收藏状态
      * @param {Boolean} plusSize 大码
      * @param {String} tspLabels tsp标签
      * @param {Boolean} sellingPoints 卖点
      * @param {Boolean} cccFeedback 负反馈
      * @param {Boolean} catName 分类名称
      * @param {Boolean} saleAttrList 销售属性（尺码等）
      * @param {Boolean} newFlashPromotion 新型闪购促销信息（新人，专属）
      * @param {Object} realTimeTspLabels 实时tsp标签 格式：{ label_cloud: ['60006672'], machine_label: ['xxx'] }
      * @param {Boolean} quickShip 快速发货
      * @param {Boolean} starComment 星级评论
      * @param {Boolean} newProductUnsale 新品待上架（coming soon）
      * @param {Boolean} promoLabel 大促角标（黑五等）
      * @param {Boolean} userBehaviorLabel 用户标签
      * @param {Boolean} userCommentKeywordsLabel 用户评论关键词标签
      * @param {Boolean} beltLabel 腰带
      * @param {Boolean} rankingList 榜单
      * @param {Boolean} estimatedPrice 到手价
   * @param {String} pageKey 页面key
   * @param {String} subPageKey 页面子key（推荐）
   * @param {String} sceneKey 场景key
   * @param {Boolean} needAssign 整合数据（将请求的每个颗粒以goods_id为键混入）
   * @param {String} caller 调用方。目前用于区分是否走abt
   * @param {Object} cccParams ccc需要的参数
   *    @param {String} cateId cateType=0或不传时，这个值传分类id/选品id，cateType=3时，这个值传goodsSn
   *    @param {Number} cateType 0 所有分类，1 频道，2 专题标识符，3 sku，4 真实分类，5 选品分类，6 标签，7 搜索词类型
   *    @param {String} realCateId 商详的真实分类id
   */
  async getAtomicInfo ({ goods, fields, itemConfig, needAssign = true, pageKey, sceneKey, subPageKey, cb, caller, cccParams }) {
    if (typeof window === 'undefined') return needAssign ? [] : {}

    // 删掉两个对实时性要求比较高的接口
    const _fields = { ...fields }
    delete _fields.wish
    delete _fields.realTimePricesWithPromotion
    const { wish, realTimePricesWithPromotion } = fields
    const { reqParams, goodsIds, goodsIdMallCodes, paramsErr } = handleParams({ goods, fields: _fields, pageKey, subPageKey, sceneKey, caller, cccParams })
    
    if (paramsErr) return needAssign ? [] : {}

    const requestQueue = [
      this.getAtom({ reqParams }),
      // 下面的接口，单独出来的目的是，上面getAtom有SW缓存2分钟
      this.getWishStatus({ goodsIds, wish }),
      this.getRealTimePriceWithPromotion({ goodsIdMallCodes, realTimePricesWithPromotion })
    ]

    let [atomicInfo, wishStatus, realTimePrices] = await Promise.all(requestQueue)
    if (atomicInfo) atomicInfo = atomicInfo.data
    if (wishStatus) wishStatus = wishStatus.data
    if (realTimePrices) realTimePrices = realTimePrices.data

    if (needAssign) return assignAtomicInfo({ goods, atomicInfo, wishStatus, realTimePrices, cb, itemConfig })
    
    let data = atomicInfo ? { ...atomicInfo } : {}
    if (wishStatus || realTimePrices) {
      const noAtomicInfo = !Object.keys(data).length
      goods.forEach(item => {
        const { goods_id } = item
        if (noAtomicInfo) {
          data[goods_id] = { ...(wishStatus?.[goods_id] || {}), ...(realTimePrices?.[goods_id] || {}) }
        } else {
          Object.assign(
            data[goods_id],
            wishStatus?.[goods_id] || {},
            realTimePrices?.[goods_id] || {}
          )
        }
      })
    }

    return data
  }

  // 获取原子信息（新）-- 除了促销预处理在客户端，其他数据预处理都在服务端
  async getAtomicInfoNew({ goods, fields = {}, pageKey, sceneKey, subPageKey, cb, caller, cccParams, isPaid, mallInfo, abtInfo }) {
    const { reqParams, paramsErr } = handleParams({ goods, fields, pageKey, subPageKey, sceneKey, caller, cccParams, isPaid, mallInfo, abtInfo })

    if (paramsErr || reqParams.fields && Object.keys(reqParams.fields).length === 0) return []
    
    const { data } = await schttp({
      method: 'POST',
      url: '/api/productAtom/atomicInfoWithDeal/get',
      data: reqParams
    })

    return assignAtomicInfo({ goods, atomicInfo: data || {}, cb })
  }
  
  // 原子数据
  async getAtom ({ reqParams }) {
    if (typeof window === 'undefined') {
      return
    }

    if (reqParams.fields && Object.keys(reqParams.fields).length === 0) return

    const atom = await schttp({
      method: 'POST',
      url: '/api/productAtom/atomicInfo/get',
      data: reqParams
    })

    return atom
  }

  // 收藏状态
  async getWishStatus ({ goodsIds, wish }) {
    if (typeof window === 'undefined') {
      return
    }

    let wishStatus = {}
    if (wish) {
      const res = await schttp({
        method: 'POST',
        url: '/api/productAtom/wishStatusInfo/get',
        data: { atomicParams: goodsIds, fields: { wish } }
      })
      if (res) wishStatus = res
    }

    return wishStatus
  }

  // 实时价格和促销
  async getRealTimePriceWithPromotion ({ goodsIdMallCodes, realTimePricesWithPromotion }) {
    if (typeof window === 'undefined') {
      return
    }

    let realTimePrices = {}
    if (realTimePricesWithPromotion && goodsIdMallCodes?.length) {
      const res = await schttp({
        method: 'POST',
        url: '/api/productAtom/realTimePricesWithPromotion/get',
        data: { atomicParams: goodsIdMallCodes, fields: { realTimePricesWithPromotion } }
      })
      if (res) realTimePrices = res
    }
    return realTimePrices
  }

  // 获取销售属性
  async getAttrInfo ({ goodsItem = null }) {
    if (!goodsItem) return
    let returnInfo = { hasAttr: false }
    const goodsId = goodsItem.goods_id

    const result = await schttp({
      url: '/api/productInfo/attr/get',
      params: { id: goodsId, _t: Date.now()  }
    })

    /**
     * code码
     * 1. '0': 成功
     * 2. '200302': 无销售属性，走默认returnInfo的值
     *  */ 
    if (result && Number(result.code) === 0) {
      if (result.info?.sale_attr_list?.[goodsId]) { // 新销售属性
        const saleAttrList = result.info.sale_attr_list[goodsId]
        const skcSaleAttr = saleAttrList.skc_sale_attr
        const skuList = saleAttrList.sku_list
        // 没有次级销售属性，但会有一个默认的sku_code，sku_code需要作为收藏入参
        if (!skcSaleAttr?.length && skuList?.length == 1) {
          returnInfo = { skuCode: skuList[0].sku_code, hasAttr: false }
        }
      }
    }

    return returnInfo
  }

  // 加入收藏
  async addWish ({ goodsItem, goodsId, goodsSn }) {
    const attrInfo = await this.getAttrInfo({ goodsItem }) // 拿销售属性，如果为无销售属性商品，收藏时需要传skuCode
    const params = { goods_id: goodsId, skc_name: goodsSn }
    if (attrInfo?.skuCode) params.sku_code = attrInfo.skuCode
    const res = await schttp({
      method: 'POST',
      url: '/api/user/wishlist/create',
      headers: {
        'x-csrf-token': gbCommonInfo.csrf_token || ''
      },
      data: params
    })

    return res
  }

  // 取消收藏
  async cancelWish ({ goodsId }) {
    const res = await schttp({
      url: `/api/user/wishlist/delete`,
      params: {
        goods_id: goodsId
      }
    })

    return res
  }

  // 获取分组列表
  async getGroupOptionalList () {
    const res = await schttp({ url: '/api/user/wishlist/group/list/get' })
    return res
  }
}

export default new ItemInterfaceSer()
